import React from "react"
import "normalize.css"
import "../styles"

import { Helmet } from "react-helmet"
import CTAButton from "../components/CTAButton/CTAButton"
import { navigate } from "gatsby"


export default () => <div >
    <Helmet>
        <title>La Forge</title>
        <html lang="fr" />
    </Helmet>
    <div style={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center' }}>

        <div className={"section"} style={{ width: '100vw', display: 'grid' }}>
            <div className={"sendedContainer"}>
                <div className={"sendedGif"} />
                <h3 style={{ color: 'white', marginBottom: '64px', textAlign: "center" }}>
                    {"Votre mail a bien été"}
                    <h3 className="blue" style={{ display: 'inline', marginLeft: ".3em" }}>{"envoyé"}</h3>
                </h3>

                <CTAButton title="RETOUR" overloadClick={() => navigate('/')} />

            </div>
        </div>
    </div>
</div>

